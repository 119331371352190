import { render, staticRenderFns } from "./Grade.vue?vue&type=template&id=2a543a36&scoped=true"
import script from "./Grade.vue?vue&type=script&lang=js"
export * from "./Grade.vue?vue&type=script&lang=js"
import style0 from "./Grade.vue?vue&type=style&index=0&id=2a543a36&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a543a36",
  null
  
)

export default component.exports